import "./entry.scss";

import React from "react";
import sr from "../../ScrollReveal";

interface EntryProps {
  id?: string;
  title: string;
  date: string;
  animOnReveal?: boolean;
  children?: React.ReactNode;
}

class Bobble extends React.Component<{skipAnim?: boolean}> {

  bobbleRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    if (this.props.skipAnim === true) return;
    const config = {
      origin: "left",
      duration: 750,
      delay: 50,
      distance: "2000px",
      scale: 1,
      easing: "ease",
    };

    sr.reveal(this.bobbleRef.current, config);

  }

  render() {

    return (
      <div className="story-entry-bobble" ref={this.bobbleRef}>
        <div className="story-entry-bobble-outer"></div>
        <div className="story-entry-bobble-inner"></div>
      </div>
    );
  }
}

class EntryLeft extends React.Component<EntryProps> {
  profileRef = React.createRef<HTMLDivElement>();

  constructor(props: EntryProps) {
    super(props);
  }

  componentDidMount(): void {
    const config = {
      origin: "left",
      duration: 1000,
      delay: 150,
      distance: "500px",
      scale: 1,
      easing: "ease",
    };

    sr.reveal(this.profileRef.current, config);
  }

  render() {
    const { id, title, date, children } = this.props;

    return (
      <div className="story-entry left" ref={this.profileRef}>
        <div className="story-entry-wrapper">
          <div className="story-entry-date-title">
            <div className="arrow-right"></div>
            <div className="story-entry-title">
              <p>{title}</p>
            </div>
            <div className="story-entry-date">
              <p>{date}</p>
            </div>
          </div>
          <div className="story-entry-description">{children}</div>
        </div>
      </div>
    );
  }
}

class EntryRight extends React.PureComponent<EntryProps> {
  profileRef = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    const config = {
      origin: "right",
      duration: 1000,
      delay: 150,
      distance: "500px",
      scale: 1,
      easing: "ease",
    };

    sr.reveal(this.profileRef.current, config);
  }

  render() {
    const { id, title, date, children } = this.props;

    return (
      <div className="story-entry right" ref={this.profileRef}>
        <div className="story-entry-wrapper">
          <div className="story-entry-date-title">
            <div className="arrow-left"></div>
            <div className="story-entry-title">
              <p>{title}</p>
            </div>
            <div className="story-entry-date">
              <p>{date}</p>
            </div>
          </div>
          <div className="story-entry-description">{children}</div>
        </div>
      </div>
    );
  }
}

class EntryMobile extends React.PureComponent<EntryProps> {
  entryRef = React.createRef<HTMLDivElement>();
  bobbleRef = React.createRef<HTMLDivElement>();

  constructor(props: EntryProps) {
    super(props);
  }

  componentDidMount(): void {
    const config = {
      origin: "right",
      duration: 750,
      delay: 50,
      distance: "2000px",
      scale: 1,
      easing: "ease",
    };

    sr.reveal(this.entryRef.current, config);

  }

  render() {
    const { id, title, date, children } = this.props;

    return (
      <div className="story-entry mobile">
        <div className="story-entry-wrapper" ref={this.entryRef}>
          <div className="story-entry-date-title">
            <Bobble/>
            <div className="arrow-left" ></div>
            <div className="story-entry-title">
              <p>{title}</p>
            </div>
            <div className="story-entry-date">
              <p>{date}</p>
            </div>
          </div>
          <div className="story-entry-description">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export { EntryLeft, EntryRight, EntryMobile, Bobble };
