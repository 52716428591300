import React from "react";
import { Link } from "react-scroll";
import "./myNavbar.scss";

import * as Icons from "../../assets/icons/icons";

import ImageSW from "../switcher/image/image.tsx";
import ThemeToggleButton from "../ToggleButton/ThemeToggleButton.tsx";
import { RevoBITsLogoTransparentsLight, RevoBITsLogoTransparentsDark } from "../../Images.js";

// const minAlpha = 0;
// let minVavY = 0;

interface NavbarProps {
  toggleTheme: () => void;
  isChecked: boolean;
  theme: string;
}

interface NavbarState {
  scrollOffset: number;
  navbarColor: string;
  isOpen: boolean;
}

class RevoNavbar extends React.Component<NavbarProps, NavbarState> {
  // navRef: React.Ref<HTMLElement>;

  constructor(props: NavbarProps) {
    super(props);
    this.state = {
      scrollOffset: -50, // get set in componentDidMount
      navbarColor: "#ffffff",
      isOpen: false,
    };
  }

  toggleMenu = () => {
    // const { isOpen } = this.state;
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  toggleThemeWrapper = () => {
    const { toggleTheme } = this.props;
    toggleTheme();
  };

  closeMenuIfOpen = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.toggleMenu();
    }
  };

  render() {
    const { isChecked, theme } = this.props;

    return (
      <nav className="navbar bg-alpha-100">
        <>
          {this.state.isOpen ? (
            <Icons.HamburgerOpen onClick={this.toggleMenu} className="menu-icon active" />
          ) : (
            <Icons.Hamburger onClick={this.toggleMenu} className="menu-icon" />
          )}
        </>

        <ImageSW
              customClassName="revologo-menu hidden-above-md"
              theme={theme}
              image_light={RevoBITsLogoTransparentsLight}
              image_dark={RevoBITsLogoTransparentsDark}
            />

        {/* <Icons.Hamburger
          onClick={this.toggleMenu}
          className={this.state.isOpen ? "menu-icon active" : "menu-icon"}
        /> */}

        <ThemeToggleButton
          toggleTheme={this.toggleThemeWrapper}
          isChecked={isChecked}
          custom_className={"hidden-above-md"}
        />

        <ul className={this.state.isOpen ? "nav-list active" : "nav-list"}>
          <li className="revo-triangle-container hidden-below-md">
            {/* <img src={Icons.RevoTriangle} className="revo-triangle hidden-below-md" alt="" /> */}
            <ImageSW
              customClassName="revologo-menu hidden-below-md"
              theme={theme}
              image_light={RevoBITsLogoTransparentsLight}
              image_dark={RevoBITsLogoTransparentsDark}
            />
          </li>
          <li>
            <Link
              to="section1"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.Home className="nav-icon" id="home-icon" />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link
              to="section2"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.About className="nav-icon" id="about-icon" />
              <span>The Team</span>
            </Link>
          </li>
          <li>
            <Link
              to="section3"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.Problem className="nav-icon" id="problem-icon" />
              <span>Problem</span>
            </Link>
          </li>
          <li>
            <Link
              to="section4"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.Solution className="nav-icon" id="solution-icon" />
              <span>Solution</span>
            </Link>
          </li>
          <li>
            <Link
              to="section5"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.Story className="nav-icon" id="story-icon" />
              <span>Story</span>
            </Link>
          </li>
          <li>
            <Link
              to="section6"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.News className="nav-icon" id="news-icon" />
              <span>News</span>
            </Link>
          </li>
          <li>
            <Link
              to="section7"
              spy={true}
              smooth={true}
              duration={700}
              offset={this.state.scrollOffset}
              className="nav-link"
              activeClass="active"
              onSetActive={() => this.closeMenuIfOpen()}
            >
              <Icons.Contact className="nav-icon" id="contact-icon" />
              <span>Contact</span>
            </Link>
          </li>
          <li className="nav-toggle-container hidden-below-md">
            <ThemeToggleButton
              toggleTheme={this.toggleThemeWrapper}
              isChecked={isChecked}
              custom_className={"hidden-below-md"}
            />
          </li>
        </ul>
      </nav>
    );
  }
}

export default RevoNavbar;
