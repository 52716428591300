import React from "react";

import "./ThemeToggleButton.scss";


interface ThemeToggleButtonProps {
  toggleTheme: () => void;
  isChecked: boolean;
  custom_className?: string;
}

class ThemeToggleButton extends React.PureComponent<ThemeToggleButtonProps> {

  render() {

    const { toggleTheme, isChecked, custom_className } = this.props;

    return (
      <>
        <input type="checkbox" id='toggle-check' onChange={toggleTheme} checked={isChecked} className={custom_className} />
        <label htmlFor='toggle-check' className={['toggle-label', custom_className].join(' ')}>
          <div className='toggle-container'>
            <div className='toggle-light-icon icon'>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx={12} cy={12} r={5} />
                <line x1={12} y1={1} x2={12} y2={3} />
                <line x1={12} y1={21} x2={12} y2={23} />
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                <line x1={1} y1={12} x2={3} y2={12} />
                <line x1={21} y1={12} x2={23} y2={12} />
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
              </svg>
            </div>
            <div className='toggle-dark-icon icon'>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
              </svg>
            </div>
            <div className='toggle-circle' />
          </div>
        </label>
      </>

    );
  }
}

export default ThemeToggleButton;